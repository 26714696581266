header h1 {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

header h1 .version {
  font-weight: 300;
  vertical-align: super;
  font-size: 10px;
}

.ant-layout-content {
  height: calc(100vh - 144px);
  overflow-y: auto;
}


.setup-innerpage-card .ant-card-head {
  text-align: center;
  font-weight: bold;
}

.welcome-card .ant-card-head {
  padding: 0px 24px;
}

.welcome-card .ant-card-type-inner .ant-card-head {
  padding: 0px 12px;
}

.innerpage-card .ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}

.ant-card-type-inner .ant-card-body {
  padding: 8px 0;
}

/* setup-data-table */

.setup-data-table table tr td {
  font-size: 16px;
}

.setup-data-table table tr td:first-child {
  font-size: 14px;
  font-weight: 500;
}

.setup-data-table table tr td.ant-table-cell-row-hover{
  background: #f3f3f3 !important;
}

.setup-data-table table tr td .value__delta .value {
  vertical-align: text-bottom;
}

.setup-data-table table tr td .value__delta .delta {
  vertical-align: super;
  font-size: 12px;
  margin-left: 8px;
}

/* Setup Upload Section */

.setup2-card.disabled .ant-card-body .ant-upload {
  background-color: #d2d2d2;
}

.ant-upload.ant-upload-drag {
  border: 1px dashed #2f2f2f;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  background: #edfff2 !important;
  border-color: green !important;
}

.ant-upload.ant-upload-drag.has-file {
  background: #edfff2 !important;
  border-color: green !important;
}

.ant-upload-drag-icon svg {
  transition: all 0.2s ease-in-out;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover
  .ant-upload-drag-icon
  svg {
  transform: scale(1.2);
}

.buy-me-cofee-notification{
  bottom: 36px !important; 
}

.privacy-policy {
  margin-left: 16px;
  padding: 16px;
  font-size: 16px;
  background: white;
}

.privacy-policy p {
  margin-bottom: 0;
}

.privacy-policy h1,
.privacy-policy h2,
.privacy-policy h3 {
  margin-bottom: 8px;
  margin-top: 16px;
}

.backers__section .backer {
  width: 100px;
  border-radius: 50%;
  margin: 0 8px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backers__section .backer a {
  color: #6c6c6c;
  text-align: center;
  font-weight: 500;
  line-height: 16px;
}


.backers__section .backer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

footer a {
  color: white;
}


.supported-cars-main-card .ant-card-head-title {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 1198px) {
  .welcome-card,
  .getting-started-card,
  .setup1-card,
  .setup2-card {
    min-height: auto !important;
  }
  .ant-upload-drag-icon svg {
    height: 48px !important;
  }
}

@media (max-width: 1024px) {
  .setup-chasis-icon {
    display: none;
 }
  .setup-section {
    display: block !important;
  }
}


@media (max-width: 576px) {
  .compatibility {
    display: none !important;
  }


  .res-no-margin {
    margin: 16px 0px !important;
  }
  
}


